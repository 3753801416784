import React, { useState } from "react";
import axios from "axios";
import { baseURL,  } from "../services/Aut.post.js"; 

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    txnid: `txn${Math.random().toString(36).substr(2, 9)}`,
    amount: "",
    productinfo: "Product Name",
    firstname: "",
    email: "",
    phone: "",
  });  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/tockens_api/generate-payment-request/`, formData);
      const { payu_url, payload } = response.data;
      
      // Create a form dynamically to post data to PayU
      const form = document.createElement("form");
      form.method = "POST";
      form.action = payu_url;

      Object.keys(payload).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = payload[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error("Error generating payment request", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="firstname" placeholder="First Name" onChange={handleChange} required />
      <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
      <input type="text" name="phone" placeholder="Phone" onChange={handleChange} required />
      <input type="number" name="amount" placeholder="Amount" onChange={handleChange} required />
      <button type="submit">Pay Now</button>
    </form>
  );
};

export default PaymentForm;
