import React, { useState, useEffect } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams , useLocation , useNavigate } from "react-router-dom";
import { baseURL, getDeal } from "../services/Aut.post.js";
import axios from "axios";

function OrderID() {
  const accessTokenforReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenforReferal?.access; // Optional chaining for safety
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const naviaget = useNavigate()

  const location = useLocation()
  const { getdealsvisit } = location.state || {};
  const dealsvisit = getdealsvisit
  

  console.log("DEEEEEEEEEEEEEEEEEEEEE" , dealsvisit)

  const { unq_id } = useParams();

  const [getDealOne, setGetDealOne] = useState(null); // Initially null for better checks
  const [getDealId, setGetDealId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  //usetsate for storing the state data 
  const [getvisitdeals , setgetvisitdeal] = useState("")

  useEffect(()=>{
    setgetvisitdeal(dealsvisit)
  })

  //retriving the data visiting tockens from localstorage activeDeal
  const visit = JSON.parse(localStorage.getItem("visitedtockens") || "{}");
  const totalVisitedTokens = dealsvisit?.visited_tockens || visit.visited_tockens;
  const visitedDealMsg = dealsvisit?.message || visit.message;
  console.log(visitedDealMsg)

  const visitedTockensMsg = () => {
    // if (!dealsvisit?.success || !visit.success) {
    if (getDealOne?.activeDeal || !getDealOne?.activeDeal){
      return <p>{visitedDealMsg}</p>; // Return JSX element
    } else {
      return null; // Return null instead of undefined
    }
  };

 
  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const data = await getDeal(unq_id, btoken);
        console.log("data", data);
        setGetDealOne(data);
        setGetDealId(data.id);
        setLoading(false); // Data fetched, stop loading
      } catch (error) {
        console.error("Error fetching deal:", error);
        setLoading(false); // Stop loading even on error
      }
    };

    fetchDeal();
  }, [unq_id, btoken]);

  const handleChange = (e) => {
    setOrderId(e.target.value);
  };

  const validate = () => {
    const newErrors = {};
    if (!orderId) {
      newErrors.orderId = "Application No is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        if (!getDealOne?.activeDeal) {
          // window.location.href = `/Deals-error`;
          naviaget("/Deals-error" , {state : {getvisitdeals , getDealOne}})
          return;
        }

        else{
          const formData = {
            order_application_id: orderId,
            dealid: getDealId,
          };
  
          const response = await axios.post(
            `${baseURL}/tockens_api/set_deal_orders/`,
            formData,
            { headers: headers }
          );
    
          console.log("response:", response.data.message);
          // window.location.href = `/confirm-page/${unq_id}`;
          naviaget(`/confirm-page/${unq_id}` ,{state : {getvisitdeals}} )
        }
        
      } catch (error) {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };


  const tockensMsg = () => {
    return(
      <>
      {totalVisitedTokens === 0 ? null : ( <p>cookie bonus tockens <span>{totalVisitedTokens}</span> added to your wallet</p>) }
      </>
    )
  }



  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
            <div className="claim-page-header">
              {loading ? (
                // Display a placeholder or loader while data is being fetched
                <img src={amazon} alt="Loading..." />
              ) : (
                <img
                  src={
                    getDealOne?.marketplace_image
                      ? `${baseURL}/${getDealOne.marketplace_image}`
                      : amazon
                  }
                  alt={getDealOne?.marketplace_image || "product"}
                />
              )}
            </div>
            <div>
              {getDealOne?.lable && <h4>{getDealOne.lable}</h4>}
             {tockensMsg()}
             
              <h3>
                <input
                  type="text"
                  placeholder="Order ID/Application No."
                  className="text-field-1"
                  onChange={handleChange}
                  value={orderId}
                />
              </h3>
              {errors.orderId && (
                <p style={{ color: "red" }}>{errors.orderId}</p>
              )}
              <button className="button-orange-1 w-80" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
          <div className="backhome">
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderID;
