
import React from 'react';
import "./Styles/ExtraOffers.css";
import successlogo from "./images/sucess-msg.png"
import { useLocation } from 'react-router-dom';
import { useState , useEffect} from 'react';

function ShoppingConfirmPage() {

  
  


  const handleClick = () => {
    const redirectUrl = `/`;
    window.location.href = redirectUrl;
  };




  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
          <img src={successlogo} alt="logo" />
          {/* <h4>Visited Tockes: <span> {NoofdealsVisited.visited_tockens}</span></h4>
          <h3>{NoofdealsVisited.message}</h3> */}
          <p>Your order is successfully submitted!</p>
          <p>Thank you for shopping with us!</p>
          <p>The information provided is stored.</p>
          <p>Once we review the provided information from Vendor, Bonus tockens will be added to wallet.</p>
          <p> This process will take a maximum of 30 days.</p>
            <h3>
            </h3>
            <button class="button-orange-1 w-80" onClick={handleClick}>Back to Home</button>
          </div>     
        </div>
      </div>
    </>
  );
}

export default ShoppingConfirmPage;
