import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner.js";
import { useParams , useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Styles/ContactUs.css";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import sucesscupon from "./images/sucess-mark.png"
import { baseURL, getProduct, getHomeDetails } from "../services/Aut.post.js"; // Make sure this path is correct

function PaymentGateWay() {
  const [successMessage, setSuccessMessage] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [btoken, setBtoken] = useState("");
  const [getDealOne, setGetDealOne] = useState({});
  const { unq_id } = useParams();
  const [homeDetailsGet, setHomeDetailsGet] = useState([]);
  const [productbuytype, setproducttype] = useState("Tockens");

  const [formData, setFormData] = useState({
    productid: unq_id,
    fullName: "",
    email: "",
    phone: "",
    address: "",
  });
  const tranId = `txn${Math.random().toString(36).substr(2, 9)}`

  const [cupon , setcupon] = useState("")
  const [updatedPrice , setUpdatedPrice] = useState("")
  const[cupondata , setcupondata] = useState("")
  const [newtockens , setnewtockens] = useState("")
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { method, updatedData } = location.state || {};
  const selectTypeCurrency = method

  console.log("Received Method:", method);
  console.log("Received UpdatedData:", updatedData);



  // Retrieve access token and user_id from local storage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
      setAccessToken(user);
      setBtoken(user.access);
    } else {
      console.error("No access token found");
    }
  }, []);

  const newheaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };


  useEffect(() => {
    const fetchData = async () => {
      if (!btoken) return;

      try {
        const productData = await getProduct(unq_id);
        setGetDealOne(productData);
        console.log("Product Data:", productData);

        // const userData = await getAllPrivatePosts();
        // if (Array.isArray(userData) && userData.length > 0) {
        //   setUserData(userData[0]);
        //   setFormData({
        //     ...formData,
        //     fullName: userData[0].first_name,
        //     email: userData[0].email,
        //     phone: userData[0].phone_number,
        //   });
        // } else {
        //   console.error("User data is not an array or is empty:", userData);
        // }

        const homeData = await getHomeDetails(btoken);
        setHomeDetailsGet(homeData);
        console.log("Home Address Details:", homeData);

        // Extracting address from 'getAddres' and setting it in formData
        if (homeData && homeData.getAddres) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            address: homeData.getAddres,  // Ensure the correct property name
          }));
        } else {
          console.error("Address not found in home data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [unq_id, btoken]);

  // Render Address
  const renderAddress = () => {
    return updatedData.present_address ? (
      <p> {updatedData.present_address}</p>
    ) : (
      <p>Address not available</p>
    );
  };

  const renderPhoneNumber = () => {
    return updatedData.phone_number ? (
      <p> {updatedData.phone_number}</p>
    ) : (
      <p>Phone number not available</p>
    );
  };

  const renderName = () => {
    return updatedData.full_name ? (
      <p>{updatedData.full_name}</p>
    ) : (
      <p>Name not available</p>
    );
  };
  
  const renderEmail = () => {
    return updatedData.email ? (
      <p>{updatedData.email}</p>
    ) : (
      <p>Email not available</p>
    );
  };
  const handleview = (type) => {
    setproducttype(type);
  };

  const Currency_Value = () => {
    const productTockens = getDealOne?.product_price || 0;
    const CurrencytoTockend = getDealOne?.currencyTotockens || 1;
    const totalMoney = productTockens * CurrencytoTockend;
    setnewtockens(totalMoney)
  
    if (!productTockens || !CurrencytoTockend) {
      return <p>Loading Currency Value...</p>;
    }
  
    return (
      <>
        {productbuytype === "Currency" || getDealOne.pay_type ? (
          <>
            {/* <h2 style={{ fontSize: "20px", color: "black" }}>Token to Money Conversion</h2>
            <p style={{ color: "Red", background: "#fae5d3", padding: "8px", borderRadius: "5px", width: "47%" }}>
              1₹ equals 10 Tokens
            </p> */}
            <h5> {totalMoney} Tockens</h5>
          </>
        ) : null}
      </>
    );
  };
  const Currency_Method = () => {
    return (
      <>
        <button className="product-tab-button">Currency</button>
      </>
    );
  };

  const Tockens_Method = () => {
    return (
      <>
        <button className="product-tab-button">Tockens</button>
      </>
    );
  };

  const both_method = () => {
    return (
      <>
        <div className="product-btn-card">
          <button
            className={
              selectTypeCurrency === "Tockens" ? "product-tab-button" : "tab-button"
            }
            onClick={() => handleview("Tockens")}
          >
            Tockens
          </button>
          <button
            className={
              selectTypeCurrency === "Currency" ? "product-tab-button" : "tab-button"
            }
            onClick={() => handleview("Currency")}
          >
            Currency
          </button>
        </div>
      </>
    );
  };

  const payment_method = () => {
    if (getDealOne.pay_type === "M") {
      return Currency_Method();
    } else if (getDealOne.pay_type === "T") {
      return Tockens_Method();
    } 
    // else if (getDealOne.pay_type === "B") {
    //   return both_method();
    // } 
    else if (method === "Tockens"){
      return Tockens_Method();
    }
    else if (method === "Currency"){
      return Currency_Method();
    }
    else {
      return <p>No Value</p>;
    }
  };

  const payment_showing = () => {
    const paytype = getDealOne?.pay_type;
    console.log("paytype" , paytype)

    if (!paytype) {
      return <p>Loading Payment Information...</p>;
    }
  
    if (paytype === "M") {
      return <h5>{getDealOne?.product_price} Currency</h5>;
    } else if (paytype === "T") {
      // return <h5>{getDealOne?.product_price} Tockens</h5>;
      return <Currency_Value />;
    } 
    // else if (paytype === "B") {
    //   return (
    //     <p className="tockens-note" style={{ paddingTop: "10px" }}>
    //       <span>
    //         {productbuytype === "Tockens" ? (
    //           Currency_Value()
    //         ) : (
    //           <h5>{getDealOne?.product_price} Currency</h5>
    //         )}
    //       </span>
    //     </p>
    //   );
    // } 
    else if (method === "Currency"){
      return <h5>{getDealOne?.product_price} Currency</h5>;
    }
    else if (method === "Tockens"){
      return <Currency_Value />;
    }
    else {
      return <p>No Value</p>;
    }
  };



  const handleSubmitTockens = async (e) => {
    e.preventDefault(); // Prevent form submission
    // Get dynamic data from the fetched data (from state or API)
    const payload = {
      txnid : tranId,
      product_id : updatedData.product_id , 
      product_name: getDealOne.product_name || "Unknown Product",   // Dynamically fetch product name
      productDescription: getDealOne.product_description || "No description available", // Dynamically fetch product description
      amount_paid: getDealOne.charges || 0,                   // Dynamically fetch shipping charges
      transactionMode: method,  // Determine transaction type
      used_tockens : newtockens,
      full_name: updatedData.full_name || "Not Provided",         // Dynamically fetch user's full name
      email: updatedData.email || "Not Provided",               // Dynamically fetch user's email
      phone_number: updatedData.phone_number || "Not Provided",
      present_address : updatedData.present_address ,      // Dynamically fetch user's phone number
      product_image : updatedData.product_image,
    };
    try {
      // Send the payload to your server for processing
      const response = await fetch(
        `${baseURL}/tockens_api/set_product_orders/`,
         {
        method: "POST",
        headers: newheaders,
        body: JSON.stringify(payload), // Convert the dynamically populated payload to JSON
      });
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Failed to process payment");
      }
  
      const data = await response.json();
  
      // If the payment is successful, show success message and redirect
      if (data.success) {
        setSuccessMessage("Payment processed successfully!");
        navigate("/"); // Redirect to a success page
      } else {
        throw new Error(data.message || "Payment failed");
      }
    } catch (error) {
      console.error("Payment Error:", error.message);
      setErrors({ form: error.message });
    }
  };
  

  const handlePaymentSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Dynamic payload preparation
    const payload = {
      txnid: `txn${Math.random().toString(36).substr(2, 9)}`,
      amount : updatedPrice,
      productinfo	: updatedData?.product_id || "Unknown Product ID",
      // productinfo : "Product Name",
      firstname	: updatedData?.full_name || "Not Provided",
      email: updatedData?.email || "Not Provided",
      phone	: updatedData?.phone_number || "Not Provided",

      product_name: getDealOne?.product_name || "Unknown Product",
      productDescription: getDealOne?.product_description || "No description available",
      // amount: getDealOne?.charges || 0,
      transactionMode: method || "Unknown Method",
      used_tockens: newtockens || 0,
      present_address: updatedData?.present_address || "Not Provided",
      product_image: updatedData?.product_image || "No Image Provided",
    };
  
    try {
      // Send the payload to the backend
      const response = await fetch(`${baseURL}/tockens_api/generate-payment-request/`, {
        method: "POST",
        headers: newheaders, // Include headers like Content-Type and Authorization if needed
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to generate payment request");
      }
  
      const data = await response.json();
  
      if (data?.payu_url && data?.payload) {
        // Create a dynamic form to redirect to PayU
        const form = document.createElement("form");
        form.method = "POST";
        form.action = data.payu_url;
  
        Object.keys(data.payload).forEach((key) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = data.payload[key];
          form.appendChild(input);
        });
  
        document.body.appendChild(form);
        form.submit(); // Automatically submit the form
      } else if (data?.success) {
        // Handle success if no redirection is required
        setSuccessMessage("Payment processed successfully!");
        navigate("/"); // Redirect to the desired page
      } else {
        throw new Error(data?.message || "Payment failed");
      }
    } catch (error) {
      console.error("Payment Error:", error.message);
      setErrors({ form: error.message }); // Display error message to the user
    }
  };

  useEffect(() => {
    if (cupondata.discount && getDealOne?.product_price) {
      const finalDiscountPrice = getDealOne.product_price - cupondata.discount;
      setUpdatedPrice(finalDiscountPrice);
    }
  }, [cupondata.discount, getDealOne?.product_price]);

  const discountPrice = () => {
    const cuponDiscount = cupondata.discount
    const acutalPrice = getDealOne?.product_price
    const finaldiscountprice = acutalPrice - cuponDiscount
    // setUpdatedPrice(finaldiscountprice)
    return(
      <div>
        {
          cuponDiscount ? (
            <><div className="discounted-container">
              <p className="current-price">₹ {getDealOne?.product_price} </p>
              <p className="disocunt-price">₹ {finaldiscountprice} </p>
              <p className="discount-off">₹ {cupondata.discount} Off </p>
              <p className="discount"> Cupon Applied
                <img src = {sucesscupon}  />
              </p>
            </div>
            </>
          )
          :   <p className="error-message">{cupondata.error || "Invalid coupon code"}</p>
        }
      </div>
    )
  }

  

  const onVerifyCuponBtn =async (e) => {
    e.preventDefault();
    const cuponVerify = {
      coupon_code : cupon , 
      product_unqcode : updatedData.product_id , 
    }
    try {
          const response = await fetch(`${baseURL}/tockens_api/coupon_verify/`, {
            method: "POST",
            headers: newheaders,
            body : JSON.stringify(cuponVerify),
          });
          const data = await response.json();
          setcupondata(data)
          console.log("User Data:", data);
          return data;
        } catch (error) {
          
          console.error("Error:", error);
        }
  }



  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Shopping Payment</h1>

          <div className="checkout-wrap">
            <div className="shopping-checkout-1">
              <div className="checkout-items-wrap">
                <div className="checkout-items-1">
                  <img
                    src={`${baseURL}/media/${getDealOne.product_image}`}
                    alt={getDealOne.product_name || "product"}
                  />
                </div>
                <div className="checkout-items-2">
                  <h4>{getDealOne.product_name}</h4>
                  <p>Shipping Charges - <span>₹ {getDealOne.charges}</span></p>
                  <p>Transaction Mode:</p>
                  <div className="product-check-out-toggle">
                    <div>
                      <div className="product-btn-card">
                        {payment_method()}  
                      </div>
                    </div>                   
                  </div>
                  {payment_showing()}
                </div>
              </div>
            </div>
          </div>

          <div className="checkout-wrap">
          <div className="shopping-checkout-1">
          <div className="customer-checkout-items-wrap">
            <h1>Customer Details</h1>
            <hr/>
            <p><span>Name : </span>{renderName()}</p>
            <p><span>Email : </span> {renderEmail()}</p>
            <p><span>Phone : </span>{renderPhoneNumber()}</p>
            <p><span>Address : </span>{renderAddress()}</p>
            {
                    getDealOne.pay_type === "T" || selectTypeCurrency === "Tockens" ? null : (
                      <div className="cupon-button-container">
                    <input 
                    type="text"
                    name = "cupon"
                    placeholder="Enter Cupon"
                    value={cupon}
                    className="cupon-input"
                    onChange={(e) => setcupon(e.target.value)}
                    />
                    <button type="button" className="verify-btn" onClick={onVerifyCuponBtn} >
                      Verify
                    </button>
                  </div>
                    )
                  }  

                  {
                    cupondata.discount ? discountPrice() :  <p className="error-message">{cupondata.error}</p>
                  }

                  <p className="tockesn-note" style={{ paddingTop: "10px" }}>
                    <span>Note :</span> No Refunds and No Returns
                  </p>

                  {
                  method === "Tockens" ? (
                    <div class="reg-1 w-100 "><button class="btn-2" type="submit" onClick={handleSubmitTockens}>Payment</button></div>
                  ) : (
                    <div class="reg-1 w-100 "><button class="btn-2" type="submit" onClick={handlePaymentSubmit}>Payment</button></div>
                  )
                 }   
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentGateWay;
