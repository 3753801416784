import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner.js";
// import "./Styles/ContactUs.css";
import "./Styles/ContactUs.css"
import axios from "axios";
import { baseURL  , dealsvisit} from "../services/Aut.post.js";

function TokenTransition() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getdealsvisit , setdealsvisit] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [tokens, setTokens] = useState(0);
  const [noTransactionsMessage, setNoTransactionsMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const SumofTockens = 0
  // const sumOfCreditedtockens = SumofTockens +(orders?.tocken);
  const sumOfCreditedtockens = orders
  .filter(order => order.credit_type === 1) // Keep only credited tokens
  .reduce((acc, order) => acc + (order.tocken || 0), SumofTockens);

  const sumOfDebitedtockens = orders
  .filter(order => order.credit_type === 0) // Keep only credited tokens
  .reduce((acc, order) => acc + (order.tocken || 0), SumofTockens);

  // console.log("bdjhfgsjkdbjhg" , sumOfCreditedtockens)
  
  localStorage.setItem("Credittockens" , sumOfCreditedtockens);
  localStorage.setItem("debitedtockens" , sumOfDebitedtockens)   

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const updatetockens = getdealsvisit || orders

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/tockens_api/list_tocken_withdraw_and_credit/`, {
          headers: headers,
        });

        console.log("API Response:", response.data);
        setOrders(response.data.tockens);
        setFilteredOrders(response.data.tockens.reverse());
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        // alert("Error fetching data. Please try again.");
      }
    };

    fetchData();
  }, []);


    //blance tockens api 
    useEffect(() => {
      const fetchHomeDetails = async () => {
        try {
    
          const response = await axios.get(`${baseURL}/userapi/gethomedetails/`, {
            headers: headers,
          })
  
          // Assuming the API response structure is as you mentioned
          const {  tockenslist} = response.data;
  
          // Update state variables
          
          setTokens(tockenslist);
          
        } catch (error) {
       // console.error("There was an error fetching the home details!", error);
          setErrorMessage("Error fetching data. Please try again."); // Set error message
        } 
      };
  
      fetchHomeDetails(); // Call the fetch function
    }, [headers]);
   

  const handleSearch = () => {
    if (!startDate || !endDate) {
      setErrorMessage("*Both start and end dates are required.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) {
      setErrorMessage("*End date cannot be earlier than start date.");
      return;
    }

    setErrorMessage(""); // Clear error

    const formattedStartDate = start.toISOString().split("T")[0];
    const formattedEndDate = end.toISOString().split("T")[0];

    axios.get(`${baseURL}/tockens_api/list_tocken_withdraw_and_credit/${formattedStartDate}/${formattedEndDate}/`, {
      headers: headers,
    })
    .then((response) => {
      const transactions = response.data.tockens;

      if (transactions && transactions.length > 0) {
        setOrders(transactions);
        setFilteredOrders(transactions.reverse());
        setNoTransactionsMessage("");
      } else {
        setOrders([]);
        setFilteredOrders([]);
        setNoTransactionsMessage(`No transactions found between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`);
      }
    })
    .catch((error) => {
      setOrders([]);
      setFilteredOrders([]);
      setNoTransactionsMessage(`No transactions are available between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`);
      console.error("There was an error fetching the data!", error);
    });
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setErrorMessage("");
    setFilteredOrders(orders.reverse()); // Reset to show all orders
    setNoTransactionsMessage("");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  //fetching the unclok deal from storagre :"{"success":false,"visited_tockens":250,"message":"Deal tokens already exist."}"
  // const unlockDeal = localStorage.getItem("visitedtockens")
  // console.log("fgbkfgb" , unlockDeal)
  // const dealvisitedtockens = unlockDeal.visited_tockens 
  // const dealmessage = unlockDeal.message 
  // const unlockDealvisitedcondition = unlockDeal.success ? null : dealvisitedtockens
  // console.log("bghfbfhgbsdkgfb" , unlockDealvisitedcondition)
  // const dealmessagecondition = unlockDeal.success ? dealmessage : null

  const unlockDeal = JSON.parse(localStorage.getItem("visitedtockens"));
console.log("unlockDeal:", unlockDeal);
// Declare variables in the outer scope
let dealmessagecondition = null;
let unlockDealvisitedcondition = null;

if (unlockDeal) {
  const dealvisitedtockens = unlockDeal.visited_tockens;
  const dealmessage = unlockDeal.message;
  const unlockDealvisitedcondition = unlockDeal.success ? dealvisitedtockens : null;
  console.log("unlockDealvisitedcondition:", unlockDealvisitedcondition);
  const dealmessagecondition = unlockDeal.success ? dealmessage : null;
  console.log("dealmessagecondition:", dealmessagecondition);
} else {
  console.log("No valid data found in localStorage for 'visitedtockens'");
}


  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <div className="wrapper-sec-1">
            <h1>Tockens Transition</h1>
            <div className="balance-tockens-lsit">
            <h2 className="tbalnce">Balance Tockens : <span className="tockens-box">{tokens}</span> </h2>
            <h2 className="tbalnce">Credited Tockens : <span className="tockens-box">{sumOfCreditedtockens}</span> </h2>
            <h2 className="tbalnce">Debited Tockens : <span className="tockens-box">{sumOfDebitedtockens}</span> </h2>
            </div>
          </div>

          {/* <div className="wrapper-sec-1">
            <h1>Tockens Transition</h1> 
            <h2></h2>
            <h2 className="tbalnce">Credited Tockens : <span className="tockens-box">{sumOfCreditedtockens}</span> </h2>
          </div>
          <div className="wrapper-sec-1">
            <h1>Tockens Transition</h1>
            <h2></h2>
            <h2 className="tbalnce">Debited Tockens : <span className="tockens-box">{sumOfDebitedtockens}</span> </h2>
          </div> */}
          
          <div className="search-i-main"> 
            <div className="search-i-1">
              <h5>Start Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="search-i-1">
              <h5>End Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="search-i-1">
              <div className="search-btns">
                <button className="button-search" onClick={handleSearch}>
                  Search
                </button>
                <button className="button-clear" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {startDate && endDate && !errorMessage && (
            <div>
              <p>
                Showing orders from <strong>{new Date(startDate).toLocaleDateString()}</strong> to <strong>{new Date(endDate).toLocaleDateString()}</strong>
              </p>
            </div>
          )}

          <div className="main-order-block">
            <div className="your-order-main">
              <div className="your-order-1-main">
                <ul>
                  <li className="your-order-1-1">Transition Date</li>
                  <li className="your-order-1-1">Action Type</li>
                  <li className="your-order-1-1">Tocken</li>
                  <li className="your-order-1-1">Withdraw Type</li>
                </ul>
              </div>
            </div>

            <div className="orders-main-list">
              {loading ? (
                <p className="Loading">Loading...</p>
              ) : currentOrders.length > 0 ? (
                currentOrders.map((order, index) => (
                  <div key={order.credit_for_id || index} className="your-order-main-list"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFF6F2" : "#ffffff",
                    }}
                  >
                    <div className="your-order-2-main">
                      <div className="your-order-1-1 date">
                        {new Date(order.add_date_time).toLocaleDateString()}
                      </div>
                      <div className="your-order-1-1 order"> {order.tocken_type || dealmessagecondition}</div>
                      <div className="your-order-1-1 tocken">{order.tocken || unlockDealvisitedcondition}</div>
                      <div className="your-order-1-1 withdraw">
                        <span className={order.credit_type === 0 ? "debited" : "credited"}>
                          {order.credit_type === 0 ? "Debited" : "Credited"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                noTransactionsMessage && (
                  <p className="no-transactions-message" style={{textAlign : "center"}}>{noTransactionsMessage}</p>
                )
              )}
            </div>
          </div>

          <div className="pagination">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenTransition;
