import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Subscriptions.css";
import axios from 'axios';
import { baseURL } from "../services/Aut.post.js";

function Subscriptions() {
  const [selectedProduct, setSelectedProduct] = useState([]);

  const handleClick = (subscriptionLevel) => {
    // Store the selected subscription level in localStorage
    localStorage.setItem('selectedSubscriptionLevel', subscriptionLevel);
    
    // Redirect to the desired URL
    window.location.href = `/getsubscriptionvalues/${subscriptionLevel}/Monthly`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/userapi/getsubscriptionvalues`);

        // Filter subscriptions where subscription_type is "Monthly"
        const monthlySubscriptions = response.data.filter(subscription =>
          subscription.subscription_type === "Monthly"
        );

        // Filter subscriptions where subscription_Cost is 0 and discounted_price is 0
        const zeroCostSubscriptions = response.data.filter(subscription =>
          subscription.subscription_Cost === 0 &&
          subscription.discounted_price === 0
        );

        // Combine both filters' results
        const combinedSubscriptions = [...zeroCostSubscriptions, ...monthlySubscriptions];

        // Remove duplicates in case some subscriptions meet both conditions
        const uniqueSubscriptions = combinedSubscriptions.filter((subscription, index, self) =>
          index === self.findIndex(s => s.id === subscription.id)
        );

        setSelectedProduct(uniqueSubscriptions);
        console.log("Filtered subscriptions:", uniqueSubscriptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <div>
      <InnerBanner />
      <div className="Subscriptions-bg-1">
        <div className="wrapper">
          <h1>Subscriptions</h1>

          <div className="Subscriptions-wrap">
            {selectedProduct.map(subscription => (
              <div key={subscription.id} className="Subscriptions-box">
                <h3 >{subscription.subscription_level}</h3>

                {/* Display only about content */}
                <span dangerouslySetInnerHTML={{ __html: subscription.subscription_About }}  style={{ color: "black" }}/>

              
                  {subscription.subscription_Cost === 0 ? (
                    <h3>
                    <span>FREE</span>
                    </h3>
                  ) : (
                    <div>
                    <h3>
                    <span>  
                      
                      <s>₹ {subscription.subscription_Cost}</s> - {subscription.discounted_price}
                    </span>
                    </h3>
                    <button 
                  onClick={() => handleClick(subscription.subscription_level)} 
                  className="btn-2 btn btn-primary btn-sm"
                >
                  Buy Now 
                </button>
                    </div>
                  )}
               


           
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
