import React, { useState } from "react";
import "./Styles/SubScriptionFeatures.css";
import symbol from "./images/symbol.png";

const SubScriptionFeatures = () => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState("");

    const handleSymbolClick = (content) => {
        setPopupContent(content);
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
        setPopupContent("");
    };

    return (
        <div className="top-space-1">
            <div className="extra-offer-wrap">
                <h1>Survey Categories</h1>
                <div className="subscription-main-container">
                    <div className="subscription-header">
                        <h1>Feature</h1>
                        <div className="subcription-type">
                            <h1>Club</h1>
                            <h1>Citizen</h1>
                            <h1>Celebrity</h1>
                            <h1>Champion</h1>
                        </div>
                    </div>
                    <hr />
                    <div className="subscription-container">
                        <div className="subsciption-side-headings">
                            <ul>
                                <li>
                                    Free
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Free: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                                <li>
                                    Tokens Yield
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Tokens Yield: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                                <li>
                                    Special Contests
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Special Contests: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                                <li>
                                    Limited contests chances
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Limited contests chances: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                                <li>
                                    Chances at Winning <br />
                                    lucky draw contests
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Chances at Winning: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                                <li>
                                    Prize cap
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Prize cap: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                                <li>
                                    Shopping with tokens
                                    <img
                                        src={symbol}
                                        alt="symbol"
                                        onClick={() => handleSymbolClick("Shopping with tokens: Detailed explanation about this feature.")}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <hr />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {popupVisible && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="close-popup" onClick={closePopup}>
                            &times;
                        </button>
                        <p>{popupContent}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubScriptionFeatures;
