import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { useParams } from "react-router-dom";
// import "./Styles/ContactUs.css";
import "./Styles/ShoppingProductDetails.css"
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EncrptionComponent from "./htmltagsencryption.js";
import { baseURL, getProduct, getHomeDetails } from "../services/Aut.post.js"; // Make sure this path is correct

function Checkout() {
  const [successMessage, setSuccessMessage] = useState("");
  const [accessToken, setAccessToken] = useState(null);

  const [btoken, setBtoken] = useState("");
  const [getDealOne,   setGetDealOne] = useState({});
  const [userData, setUserData] = useState({});
  const {  product_unqcode } = useParams();
  const [homeDetailsGet, setHomeDetailsGet] = useState([]);
  const [formData, setFormData] = useState({
    productid: product_unqcode,
    fullName: "",   
    email: "",                
    phone: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // const [userId, setUserId] = useState(null); // To store the dynamic user_id

  // Retrieve access token and user_id from local storage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
      setAccessToken(user);
      setBtoken(user.access);
      // setUserId(user.user_id); // Assuming user_id is stored in the user object
    } else {
      console.error("No access token found");
    }
  }, []);

  const newheaders = {
     "Content-Type": "application/json",
     Authorization: `Bearer ${String(btoken)}`,
  };

  const getAllPrivatePosts = async () => {
    try {
      const response = await fetch(`${baseURL}/userapi/userdetails/`, {
        method: "POST",
        headers: newheaders,
      });
      const data = await response.json();
      console.log("data", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await getProduct(product_unqcode);
        setGetDealOne(productData);
        console.log("PPPRODUCTSHOPINGNN0" , productData)
      } catch (error) {
        console.error("Error fetching product data:", error);
      }  
    };

    const fetchUserData = async () => {
      try {
        const data = await getAllPrivatePosts();
        if (Array.isArray(data) && data.length > 0) {
          setUserData(data[0]);
          setFormData({
            ...formData,
            fullName: data[0].first_name,
            email: data[0].email,
            phone: data[0].phone_number,
          });
        } else {
          console.error("User data is not an array or is empty:", data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
    if (btoken) {
      fetchUserData();
    }
  }, [product_unqcode, btoken]);

  // Retrieving user Address:
  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const data = await getHomeDetails(btoken);
        setHomeDetailsGet(data);
        console.log("User Address Details:", data);

        // Extracting address from 'getAddres'
        if (data && data.getAddres) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            address: data.getAddres,
          }));
        }
      } catch (error) {
        console.log("Error fetching address:", error);
      }
    };

    if (btoken) {
      fetchHomeData();
    }
  }, [btoken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) {
      newErrors.fullName = "Full Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\+91\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }
    if (!formData.address) newErrors.address = "Address is required";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Prepare the final data structure to be sent
    const finalData = {
      product_id: product_unqcode,
      present_address: formData.address,
      amount_paid: getDealOne.charges,
      used_tockens: getDealOne.product_tockens,
      full_name: formData.fullName,
      email: formData.email,
      phone_number: formData.phone,
      product_image: getDealOne.product_image,
      product_name: getDealOne.product_name,
      user_id: "",
    };

    console.log("Submitting finalData:", finalData);

    try {
      const response = await fetch(
        `${baseURL}/tockens_api/set_product_orders/`,
        {
          method: "POST",
          headers: newheaders,
          body: JSON.stringify(finalData),
        }
      );

      if (response.ok) {
        // Redirect on successful form submission
        const redirectUrl = `/confirm-page/${product_unqcode}`;
        window.location.href = redirectUrl;
      } else {
        const errorData = await response.json();
        console.error("Error Response:", errorData);
        alert(`Error submitting form: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("Network error occurred. Please try again later.");
    }
  };

  const onClickProceedProduct = () => {
    navigate(`/Checkout/${product_unqcode}`)
  }

  const onClickbackbtn = () => {
  navigate(`/shopping/category/${getDealOne.id}`);
  }


  const IfpaytypeisMoney = () => {
    const tockensPrice = getDealOne.product_price;
    const currencyTotockens = getDealOne.currencyTotockens;
    const totalValue = tockensPrice/currencyTotockens
    return(
      <>
      {getDealOne.pay_type === "B" || getDealOne.pay_type === "M" ? (
          // <p>₹ {totalValue} Rupees</p>
          <>
             {/* <p>₹ {totalValue} Rupees</p> */}
            {/* <h3>Token to Money Conversion</h3> */}
            {/* <p style={{ color: "Red" }}>1₹ equals 10 Tokens</p> */}
          
            <h6 className="s-p-details-tockens">₹ {totalValue} Rupees</h6></>
        ) : (
          null
        )}
      </>
    )
  }

  

  const currencyValue = () => {
    const tockensPrice = getDealOne.product_price;
    const currencyTotockens = getDealOne.currencyTotockens;
    const totalValue = tockensPrice * currencyTotockens;
  
    return (
      <div style={{ display: "flex" }}>
        {getDealOne.pay_type === "B" && (
          <>
            <h6 className="s-p-details-tockens">{totalValue} Tockens</h6> 
            <h6 style={{fontWeight:500, alignSelf : "center"}}>OR</h6>
            <h6 className="s-p-details-tockens">₹ {tockensPrice} Rupees</h6>
          </>
        )}
        {getDealOne.pay_type === "M" && (
          <h6 className="s-p-details-tockens">₹ {tockensPrice} Rupees</h6>
        )}
        {getDealOne.pay_type === "T" && (
          <h6 className="s-p-details-tockens">{totalValue} Tockens</h6>
        )}
      </div>
    );
  };
  

 
  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Shopping Product Details</h1>

          <div className="p-d-container">
          <div className="product-details-section-1">
                  <img
                    src={`${baseURL}/media/${getDealOne.product_image}`}
                    alt={getDealOne.product_name || "product"} width={149} height={151}
                  />
              </div>
              <div className="product-details-section-2">
              <h4 className="s-p-heading">{getDealOne.product_name}</h4>
            
              {/* <h6 className="s-p-details-tockens">{currencyValue()}</h6> */}

              <div style={{display:"flex"}}>
             
              {currencyValue()}
              </div>
             
              <p className="s-p-charges">Shipping Charges - <spn className = "s-p-span">₹ {getDealOne.charges}</spn></p>
              <p className="product-details-description">
                      {/* {getDealOne.description} */}
                      <EncrptionComponent content={getDealOne.description} />
              </p>
             <div className="s-p-button-container">
             <button className="button-orange-1 w-auto" type = "button" onClick={onClickProceedProduct}>Proceed To Buy Product</button>
             <button className="back-btn w-auto" type = "button" onClick={onClickbackbtn}>Back</button>
              </div>     
          </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default Checkout;

/*
 <div className="checkout-wrap">
            <div className="checkout-1">

              <div className="product-details-items-wrap">
                <div className="product-details-items-1">
                  <img
                    src={`${baseURL}/media/${getDealOne.product_image}`}
                    alt={getDealOne.product_name || "product"} width={149} height={151}
                  />
                </div>
                <div className="shopping-product-details-items-2">
                  <h4 className="s-p-heading">{getDealOne.product_name}</h4>
                  <h5 className="s-p-details-tockens">{getDealOne.product_tockens} Tockens</h5>
                  <p className="s-p-charges">Shipping Charges - <spn className = "s-p-span">₹ {getDealOne.charges}</spn></p>
                  <p className="product-details-description">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                  </p>
                  <button className="button-orange-1 w-auto" type = "button" onClick={onClickProceedProduct}>Proceed To Buy Product</button>
                </div>
              </div>
            </div>
             <div className="checkout-2">
              <h3>Shipping Address</h3>
              <div>
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
              </div>

              <div>
                <Form onSubmit={handleSubmit}>
                  <div className="checkout-form-wrap">
                    <div className="reg-1 w-100">
                      <input
                        type="text"
                        name="fullName"
                        className="text-f-1"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                      {errors.fullName && (
                        <div className="error">{errors.fullName}</div>
                      )}
                    </div>

                    <div className="reg-1 w-100">
                      <input
                        type="email"
                        name="email"
                        className="text-f-1"
                        placeholder="E-mail"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>

                    <div className="reg-1 w-100">
                      <input
                        type="text"
                        name="phone"
                        className="text-f-1"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <div className="error">{errors.phone}</div>
                      )}
                    </div>
                    <div className="reg-1 w-100">
                      <textarea
                        name="address"
                        className="text-f-1"
                        placeholder="Full Address"
                        value={formData.address}
                        onChange={handleChange}
                      ></textarea>
                      {errors.address && (
                        <div className="error">{errors.address}</div>
                      )}
                    </div>

                    <div className="reg-1 w-100 text-center">
                      <button className="btn-2" type="submit">Confirm & Pay Now</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div> 
            // </div>
//  */